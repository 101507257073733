<template>
  <div>
    <a-card
      v-if="deviceType"
      :title="deviceType.name"
      :bordered="true"
      style="float: left; width: 24%; margin-left: 16.8%"
    >
      <p>{{ `设备类型Id: ${deviceType.deviceTypeId}` }}</p>
      <p>{{ `英文名: ${deviceType.enName}` }}</p>
    </a-card>
    <a-card
      v-if="deviceType && deviceType.deviceTypeId === $constRepository.deviceType.DEVICE_TYPE.空调"
      :title="'按键Id规则'"
      :bordered="true"
      style="float: left; width: 31%; margin-left: 3%; margin-bottom: 2%"
    >
      <p>{{ '模式: M0(制冷), M1(制热), M2(自动), M3(送风), M4(除湿)' }}</p>
      <p>{{ '温度: T16-T30(16-30℃, 自动模式不支持温度调节)' }}</p>
      <p>{{ '风量: S0(自动), S1(小风量), S2(中风量), S3(大风量)' }}</p>
      <p>{{ '实例: M0_T16_S0(模式：制冷, 温度：16, 风速：自动)' }}</p>
    </a-card>
    <a-divider style="margin-top: 3%" />
    <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" class="template-form">
      <a-form-model-item label="描述" prop="name">
        <a-input v-model="form.remark" />
      </a-form-model-item>
      <a-form-item label="按键列表:">
        <a-button type="primary" @click="handleAdd" class="editable-add-btn">添加新按钮</a-button>
        <div class="btn-group">
          <a-button type="primary" class="download-excel" @click="exportJSON">导出</a-button>
          <label for="fileinp">
            <a-button type="primary" class="btn"
              >导入
              <input
                type="file"
                name="filename"
                ref="upload"
                accept=".json"
                class="outputlist_upload"
                id="fileinp"
                multiple="multiple"
              />
            </a-button>
          </label>
        </div>
        <a-table
          bordered
          :data-source="form.keys"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <a-table-column title="按键ID" width="150px">
            <template slot-scope="scope">
              <div>
                <div class="table-div" v-if="!scope.editable">{{ scope.keyId }}</div>
                <a-textarea v-else class="table-textarea" :autoSize="true" v-model="scope.keyId" clearable></a-textarea>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="按键名称" width="150px">
            <template slot-scope="scope">
              <div>
                <div class="table-div" v-if="!scope.editable">{{ scope.keyName }}</div>
                <a-textarea
                  v-else
                  class="table-textarea"
                  :autoSize="true"
                  v-model="scope.keyName"
                  clearable
                ></a-textarea>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="按键描述" width="150px">
            <template slot-scope="scope">
              <div>
                <div class="table-div" v-if="!scope.editable">{{ scope.displayName }}</div>
                <a-textarea
                  v-else
                  class="table-textarea"
                  :autoSize="true"
                  v-model="scope.displayName"
                  clearable
                ></a-textarea>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="操作" width="150px">
            <template slot-scope="scope">
              <a-button type="link" size="small" @click="edit(scope)" v-if="!scope.editable">编辑</a-button>
              <a-button type="link" size="small" @click="save(scope)" v-else>保存</a-button>
              <a-divider type="vertical" />
              <a-button class="red-btn" ghost @click="onDelete(form.keys, scope)">删除</a-button>
            </template>
          </a-table-column>
        </a-table>
      </a-form-item>
      <a-form-item class="group-btn">
        <a-button type="primary" @click="updateTemplate">确定</a-button>
        <a-button class="creator-btn" @click="cancel">返回</a-button>
      </a-form-item>
    </a-form-model>
  </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
  name: 'TemplateEdit',
  components: {},
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      deviceType: undefined,
      form: {},
      ImportJSON: {},
    };
  },
  mounted() {
    this.deviceType = this.$route.params.deviceType;
    if (!this.deviceType) {
      this.$router.back();
    } else {
      this.$refs.upload.addEventListener('change', (e) => {
        this.importJSON(e);
      });
      this.getTemplate(this.deviceType.deviceTypeId);
      this.form.deviceTypeId = this.deviceType.deviceTypeId;
      this.form.deviceTypeName = this.deviceType.name;
    }
  },
  methods: {
    exportJSON() {
      // 将json转换成字符串
      const data = JSON.stringify(this.form);
      const blob = new Blob([data], { type: '' });
      FileSaver.saveAs(blob, `${this.deviceType.name}模板.json`);
    },
    // 导入json
    importJSON() {
      const file = document.getElementById('fileinp').files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        this.form.keys = JSON.parse(reader.result).keys;
      };
    },
    save(value) {
      this.$set(value, 'editable', false);
    },
    edit(value) {
      this.$set(value, 'editable', true);
    },
    onDelete(data, scope) {
      const dataSource = [...data].filter((item) => item !== scope);
      dataSource.forEach((dataIndex, index) => {
        this.$set(dataIndex, 'key', index);
      });
      switch (data) {
        case this.form.keys:
          this.form.keys = dataSource;
          break;
        case this.form.modes:
          this.form.modes = dataSource;
          break;
        case this.form.keys[this.modalKey].exts:
          this.form.keys[this.modalKey].exts = dataSource;
          break;
        default:
          break;
      }
    },
    handleAdd() {
      const newData = {
        key: this.form.keys.length,
        keyId: '',
        displayName: '',
        keyName: '',
      };
      this.form.keys.push(newData);
    },
    getTemplate(deviceTypeId) {
      this.$apiManager.template.getTemplates(deviceTypeId).then((templates) => {
        if (templates.data.keys) {
          this.form = templates.data;
        } else {
          this.form = { ...templates.data, keys: [] };
        }
        this.form.deviceTypeId = this.deviceType.deviceTypeId;
        this.form.deviceTypeName = this.deviceType.name;
      });
    },
    cancel() {
      this.$router.back();
    },
    updateTemplate() {
      this.$apiManager.template.updateTemplates(this.form).then(() => {
        this.$message.success('修改成功');
      });
    },
  },
};
</script>

<style lang="less" scoped>
.editable-add-btn {
  float: right;
  z-index: 100;
  margin: 5px 0px;
}
.template-form {
  margin-top: 3%;
}
.creator-btn {
  margin-left: 5%;
}
.device-type-operations {
  display: flex;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .deviceType-table {
    margin-top: 2%;
  }
}
.btn {
  margin-top: 1%;
}
#fileinp {
  float: left;
  margin-left: 2%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  .btn {
    margin-left: 2%;
  }
}
.group-btn {
  margin-left: 16.7%;
}
.red-btn {
  color: red;
  border: none;
}
.download-excel {
  margin-right: 2%;
  z-index: 100;
  float: left;
  margin-top: 1%;
}
</style>
